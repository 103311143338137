import { ref } from 'vue'
import type { QuotePayload } from '~/types';

export function useQuotePayload() {
  const quotePayload = ref<QuotePayload>({
    issueStateAbbreviation: '',
    primaryInsured: {
      coverages: [
        {
          coverageLookup: 'Base',
          options: {
            benefitPackage: {
              applicationValue: 'Complete'
            },
            coverageType: {
              applicationValue: 'TwentyFourHour'
            },
            insuredOption: {
              applicationValue: null
            }
          }
        },
        {
          coverageLookup: 'AccidentalDeathBenefit'
        }
      ],
      person: {
        age: null,
      }
    }
  })
  return {
    quotePayload
  }
}