<template>
  <section id="form-quote-start" class="rel quote-start-section">
    <v-container class="pa-0 ">
      <div class="mx-auto">
        <v-card 
          class="mx-auto align-items-center border-shadow-card" 
          :max-width="maxWidth"
          >
            <v-toolbar>
              <v-toolbar-title class="text-h5">
                {{ formTitle }}
              </v-toolbar-title>
            </v-toolbar>
            <v-divider class="mt-0"/>
            <v-card-subtitle v-if="formSubtitle">
              <h5>{{ formSubtitle }}</h5>
            </v-card-subtitle>
            <v-card-text>
            <v-form name="requestForm" ref="requestForm" class="contact-form">
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="quotePayload.issueStateAbbreviation"
                    ref="issueStateAbbreviation"
                    :items="states"
                    :variant="inputVariant"
                    :rules="requiredRules"
                    :loading="statesPending"
                    bg-color="white"
                    item-title="name"
                    item-value="code"
                    label="Your Resident State"
                    autocomplete="off"
                    hide-details="auto"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="quotePayload.primaryInsured.person.age"
                    :variant="inputVariant"
                    :rules="ageRules"
                    bg-color="white"
                    type="number"
                    inputmode="numeric"
                    pattern="[0-9]*"
                    name="age"
                    label="Your Age"
                    autocomplete="off"
                    hide-details="auto"
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="applicationInfo.product.primaryInsured.person.name.first"
                    :variant="inputVariant"
                    type="firstName"
                    name="firstName"
                    label="Your First Name"
                    autocomplete="off"
                    :rules="requiredRules"
                    required
                    hide-details="auto"
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="applicationInfo.product.primaryInsured.person.name.last"
                    :variant="inputVariant"
                    type="lastName"
                    name="lastName"
                    label="Your Last Name"
                    autocomplete="off"
                    :rules="requiredRules"
                    required
                    hide-details="auto"
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="applicationInfo.product.primaryInsured.person.name.suffix"
                    :variant="inputVariant"
                    type="suffix"
                    name="suffix"
                    label="Your Suffix"
                    autocomplete="off"
                    hide-details="auto"
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="applicationInfo.product.primaryInsured.person.emailAddress"
                    :variant="inputVariant"
                    type="email"
                    name="email"
                    label="Your Email"
                    autocomplete="off"
                    :rules="emailRules"
                    required
                    hide-details="auto"
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    :value="phoneNumber"
                    :variant="inputVariant"
                    name="phone"
                    label="Your Phone Number"
                    autocomplete="off"
                    :rules="phoneNumberRules"
                    hide-details="auto"
                    v-maska:phoneNumber.unmasked="phoneMaskOptions"
                    inputmode="numeric"
                    pattern="[0-9]*"
                  />
                </v-col>
                <v-col cols="12">
                  <div class="text-justify text-caption">
                    <div v-html="quoteContactConsent"></div>
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-divider class="mb-0"/>
          <v-card-actions v-if="isSecondaryAction" class="d-flex justify-end my-1">
            <v-btn
              variant="flat"
              size="large"
              light
              @click="submitForm"
              color="secondary"
            >
            {{ actionTitle }}
            </v-btn>
          </v-card-actions>
          <v-card-actions v-else class="d-flex justify-end color--white my-1">
            <v-btn
              variant="flat"
              size="large"
              light
              @click="submitForm"
            >
            {{ actionTitle }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </v-container>
  </section>
</template>

<script lang="ts">
import eventBus from '~/utils/eventBus'
import { useDisplay } from 'vuetify'
export default defineComponent({
  name: "ComponentsFormsQuoteStart",
  props: {
    heroView: {
      type: Boolean as () => boolean,
      default: () => false
    },
    showFormTitle: {
      type: Boolean as () => boolean,
      default: () => true
    },
    formTitle: {
      type: String as () => string,
      default: () => "Let's Get Started",
    },
    formSubtitle: {
      type: String as () => string,
      default: () => undefined,
    },
    stepId: {
      type: String as () => string,
      default: () => "STEP01",
    },
    actionTitle: {
      type: String as () => string,
      default: () => "Continue",
    },
    inputVariant: {
      type: String as () => "outlined" | "plain" | "underlined" | "filled" | "solo" | "solo-inverted" | "solo-filled" | undefined,
      default: () => "outlined",

    },
    isSecondaryAction: {
      type: Boolean as () => boolean,
      default: () => false
    }
  },
  setup(props: any) {
    const { stepId } = props
    const config = useRuntimeConfig()
    const states = ref<State[] | []>([])
    const statesPending = ref<boolean>(false)
    async function getApprovedStates() {
      statesPending.value = true
      try {
        const response = await fetch(`/webapi/approved-states`, {
          method: 'GET',
        })
        if (response.ok) {
          const newValue = await response.json()
          states.value = newValue || []
        } else {
          states.value = []
        }

      } catch (error: any) {
        states.value = []
      } finally {
        statesPending.value = false
      }
    }
    getApprovedStates()
    const quotePayload = ref<QuotePayload>(useQuotePayload().quotePayload.value);
    const quotePayloadStore = storeToRefs(useQuotePayloadStore()).quotePayload.value;
    if(!_isEmpty(quotePayloadStore)) {
      quotePayload.value = quotePayloadStore;
    }

    const consentCheckbox = ref(false);
    const phoneNumber = ref("");
    const phoneMaskOptions = {mask: '(###) ###-####'};
    const applicationInfo = ref<ApplicationInfo>(useApplicationInfo().applicationInfo.value)
    const applicationInfoStore = storeToRefs(useApplicationInfoStore()).applicationInfo.value
    if(!_isEmpty(applicationInfoStore)) {
      applicationInfo.value = applicationInfoStore
    }
    phoneNumber.value = `${applicationInfo.value.product.primaryInsured.phone.areaCode}${applicationInfo.value.product.primaryInsured.phone.number}`

    const requiredRules = [
      (value: any) => {
        if (value && value.trim().length > 0) return true
        return 'Required.'
      },
    ]

    const emailRules = [
      (value: any) => {
        if (value) return true
        return 'Required.'
      },
      (value: any) => {
        if (validateEmail(value)) return true
        return 'Invalid Email Address.'
      }
    ]

    const phoneNumberRules = [
      (value: any) => {
        if (value) return true
        return 'Required.'
      },
      (value: any) => {
        if (validatePhoneNumber(value)) return true
        return 'Invalid Phone Number.'
      }
    ]

    const policyMinAge = parseInt(config.public.policyMinAge)
    const policyMaxAge = parseInt(config.public.policyMaxAge)
    const ageRules = [
      (value: any) => {
        if (value) return true
        return 'Required.'
      }, 
      (value: any) => {
        if (value >= policyMinAge) return true
        return `Age should not be less than ${policyMinAge}`
      },
      (value: any) => {
        if (value <= policyMaxAge) return true
        return `Age should not be greater than ${policyMaxAge}`
      }
    ]

    function getIgnoreCase<T>(object: Record<string, any> | null, path: string | string[], defaultValue: T): T | any {
      if (!object) return defaultValue;

      const paths = Array.isArray(path) ? path : path.split('.');
      let current: any = object;

      for (const key of paths) {
        if (current) {
          const lowerKey = String(key).toLowerCase();
          const foundKey = Object.keys(current).find(k => k.toLowerCase() === lowerKey);

          if (foundKey) {
            current = current[foundKey];
          } else {
            return defaultValue;
          }
        } else {
          return defaultValue;
        }
      }

      return current;
    }


    const issueStateAbbreviation = ref();
    const focusFirstField = () => {
      issueStateAbbreviation.value?.focus()
    }
    onMounted(() => {
      eventBus.on('focusQuoteFormFirstField', focusFirstField)
    })

    onBeforeUnmount(() => {
      eventBus.off('focusQuoteFormFirstField', focusFirstField)
    })

    const requestForm = ref();
    const submitForm = async () => {
      const { valid } = await _get(requestForm, "value", "").validate()
      if(valid) { 
        useQuotePayloadStore().setQuotePayloadValue(quotePayload.value)

        setDeepValue(applicationInfo.value, 'product.primaryInsured.phone.areaCode', phoneNumber.value.slice(0, 3))
        setDeepValue(applicationInfo.value, 'product.primaryInsured.phone.number', phoneNumber.value.slice(3))
        setDeepValue(applicationInfo.value, 'product.issueStateAbbreviation', quotePayload.value.issueStateAbbreviation)
        useApplicationInfoStore().setApplicationInfoValue(applicationInfo.value)

        const contactInfo = ref<ContactInfo>(useContactInfo().contactInfo.value)
        contactInfo.value = {
          properties: {
            email: _get(applicationInfo.value, "product.primaryInsured.person.emailAddress", ""),
            firstname: _get(applicationInfo.value, "product.primaryInsured.person.name.first", ""),
            lastname: _get(applicationInfo.value, "product.primaryInsured.person.name.last", ""),
            suffix: _get(applicationInfo.value, "product.primaryInsured.person.name.suffix", ""),
            last_step_in_assurity: stepId,
            assurity_case_id: _get(applicationInfo.value, "caseId", ""),
            instance: config.public.instanceName,
            assurity_policy_number: "",
            phone: phoneNumber.value,
            age: _get(quotePayload.value, "primaryInsured.person.age", undefined),
            state: _get(quotePayload.value, "issueStateAbbreviation", ""),
          }
        };

        let queryParams = useQueryParamsStore().queryParams
        if(isProxy(queryParams)) {
          queryParams = toRaw(queryParams);
        }
        if(!_isEmpty(queryParams)) {
          setDeepValue(contactInfo.value, 'properties.agent_url_slug', getIgnoreCase(queryParams, "agent", ""))
          setDeepValue(contactInfo.value, 'properties.company_url_slug', getIgnoreCase(queryParams, "company", ""))
        }
        useContactInfoStore().setContactInfoValue(contactInfo.value)
        try{
          await useContactInfo().postContactInfo(stepId)
        } catch (error) {
          console.warn("postContactInfo-error: ", error)
          const email = _get(contactInfo.value, "properties.email", "")
          try{
            const hsContactInfo = await useContactInfo().getContactInfo(email)
            const contactInfoId = _get(hsContactInfo, "id")
            if(contactInfoId) {
              setDeepValue(contactInfo.value, 'id', contactInfoId)
              if(!_isEmpty(queryParams)) {
                setDeepValue(contactInfo.value, 'properties.agent_url_slug', getIgnoreCase(queryParams, "agent", ""))
                setDeepValue(contactInfo.value, 'properties.company_url_slug', getIgnoreCase(queryParams, "company", ""))
              }
              useContactInfoStore().setContactInfoValue(contactInfo.value)
              try{
                await useContactInfo().putContactInfo(stepId)
              } catch(err) {
                console.warn("updateContactInfo-error: ", err)
              }
            }
          } catch(err) {
            console.warn("getContactInfo-error: ", err)
          }
        } 
        return navigateTo({ path: '/forms/quote/tiers' });
      }
    }
    const quoteContactConsent = _get(useRuntimeConfig().public, 'quoteContactConsent', '')
    const display = ref(useDisplay())
    const maxWidth = computed(() => {
      if (display.value.lgAndUp) {
        return 600
      }
      return undefined
    })
    return { 
      states, 
      statesPending, 
      requestForm, 
      issueStateAbbreviation,
      requiredRules, 
      emailRules,
      phoneNumberRules,
      ageRules,
      submitForm, 
      quotePayload,
      applicationInfo, 
      phoneMaskOptions, 
      phoneNumber,
      consentCheckbox,
      quoteContactConsent,
      maxWidth
    };
  },
});
</script>
